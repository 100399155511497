:root {
  --c-primary: #40061a;
  --c-secondary: #ec0328;
  --ff: "Inter", sans-serif;
  --filter-primary: invert(9%) sepia(68%) saturate(2918%) hue-rotate(321deg) brightness(70%) contrast(103%);
  --filter-secondary: invert(14%) sepia(84%) saturate(6798%) hue-rotate(346deg) brightness(91%) contrast(104%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #ec0328;
  color: var(--c-secondary);
}

body {
  font-family: Inter, sans-serif;
  font-family: var(--ff);
  color: #40061a;
  color: var(--c-primary);
  background-color: #ecdcdc;
}

strong {
  font-weight: 600;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  70% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  70% {
    opacity: .4;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 575.98px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo {
  width: 325px;
  height: 400px;
  z-index: -1;
  filter: contrast(150%);
  background-image: url("logo.e4fbf347.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px auto 30px;
  position: relative;
}

@media (max-width: 575.98px) {
  .logo {
    width: 203px;
    height: 250px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.logo:after {
  content: "";
  width: 325px;
  height: 400px;
  background-image: url("logo-2.ab9363cb.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  animation: logoAnimation 1.2s infinite alternate;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 575.98px) {
  .logo:after {
    width: 203px;
    height: 250px;
  }
}

@keyframes logoAnimation {
  from {
    opacity: 0;
    filter: blur();
  }

  to {
    opacity: 1;
  }
}

.card {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.card + .card {
  margin-top: 100px;
}

@media (max-width: 575.98px) {
  .card + .card {
    margin-top: 30px;
  }
}

@media (max-width: 991.98px) {
  .card {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

.card-content {
  max-width: 800px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .card-content {
    z-index: 333;
    margin-top: 20px;
  }
}

.card-title {
  margin-bottom: 20px;
  font-size: 46px;
  font-weight: 800;
  animation: slideAnimation .8s ease-in;
}

@media (max-width: 767.98px) {
  .card-title {
    font-size: 34px;
  }
}

.card-text {
  border-bottom: 1px dashed rgba(0, 0, 0, .1);
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 28px;
  line-height: 34px;
  animation: opacityAnimation 1.3s ease-out;
}

@media (max-width: 767.98px) {
  .card-text {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
  }
}

.card-info {
  font-size: 18px;
  line-height: 28px;
  animation: opacityAnimation 1.6s ease-out;
}

@media (max-width: 767.98px) {
  .card-info {
    font-size: 15px;
    line-height: 24px;
  }
}

.card-mail {
  transition: all .15s ease-out;
  display: inline-flex;
}

.card-mail:hover {
  transform: scale(1.02)translateY(-2px);
}

.card-bottom {
  animation: cardBottom 2s ease-out;
}

.card-social {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.card-social:hover a {
  opacity: .6;
}

.card-social a {
  transition: all .15s ease-out;
  display: inline-flex;
}

.card-social a img {
  filter: invert(9%) sepia(68%) saturate(2918%) hue-rotate(321deg) brightness(70%) contrast(103%);
  filter: var(--filter-primary);
}

.card-social a:hover {
  opacity: 1;
  transform: scale(1.1)translateY(-4px);
}

.card-social a:hover img {
  filter: invert(14%) sepia(84%) saturate(6798%) hue-rotate(346deg) brightness(91%) contrast(104%);
  filter: var(--filter-secondary);
}

.card-social a + a {
  margin-left: 15px;
}

span.fancySpan {
  opacity: 0;
  transition: all .4s;
}

span.fancySpan.fade {
  opacity: 1;
}

@media screen and (orientation: landscape) {
  @media (max-width: 991.98px) {
    .card {
      height: 100%;
    }
  }
}

.work-item {
  filter: grayscale(99%);
  background-color: rgba(255, 255, 255, .5);
  border-radius: 20px;
  margin-bottom: 50px;
  transition: all .3s;
  box-shadow: 0 40px 80px rgba(0, 0, 0, .06);
}

.work-item:hover {
  filter: grayscale(0);
  transform: translateY(-3px);
}

.work-item:nth-child(1) {
  animation: slideAnimation 1s ease-out;
}

.work-item:nth-child(2) {
  animation: slideAnimation 1.5s ease-out;
}

.work-item:nth-child(3) {
  animation: slideAnimation 2s ease-out;
}

.work-img {
  width: 100%;
  height: 420px;
  border-radius: 20px;
  display: block;
  overflow: hidden;
}

@media (max-width: 575.98px) {
  .work-img {
    height: auto;
  }
}

.work-img img {
  width: 100%;
  object-position: top;
}

.work-head {
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  display: flex;
}

@media (max-width: 575.98px) {
  .work-head {
    flex-direction: column;
  }

  .work-head .work-title {
    margin-bottom: 10px;
  }
}

.work-title {
  font-size: 20px;
  font-weight: 700;
}

.work-buttons {
  justify-content: center;
  align-items: center;
  display: flex;
}

.work-buttons .btn {
  height: 38px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  display: flex;
}

.work-buttons .btn:first-child {
  color: #fff;
  background-color: #ec0328;
  background-color: var(--c-secondary);
}

.work-buttons .btn:last-child {
  color: #ec0328;
  color: var(--c-secondary);
  border: 1px solid #ec0328;
  border: 1px solid var(--c-secondary);
  margin-left: 5px;
}

/*# sourceMappingURL=index.13247e09.css.map */
